/* You can add global styles to this file, and also import other style files */
.spacingTitle{
    letter-spacing: 3px;
}
.formatLogo{
    max-height: 50px;
}
h3{
    font-size: large;
}

h4{
    font-size: medium;
}

h5{
    font-size: small;
}
.cursor{
    cursor: pointer;
}

.main-height{
    min-height:100vh;
}
.navChar{
    font-size: 12px;
}
 /*table, th, td {
    border: 1px solid grey;
    border-collapse: collapse;
    font-size: 10px; 
}*/
/*table, tr, td {
    border-collapse: collapse; 
    border-bottom: 1px solid black;
}

table td:first-child {
    border-left:1px solid black;
}

table td:last-child {
    border-right:1px solid black;
}*/

table{
    margin-left: auto;
    margin-right: auto;
    table-layout: auto;
    width: 100%;
    max-width: 40em;
    font-size: 10px; 
}

.title-category{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: small;
}

input{
    font-size: 10px;
}

.nav-link {
    color: black;
}
